<template>
  <div class="bg-black" v-if="catalog">
    <div>
      <div v-if="catalog">
        <div class="flex justify-between px-4 mt-6">
          <div>
            <router-link :to="{ name: 'Catalog' }">
              <img
                :src="require('@/assets/images/logo-dark.webp')"
                class="w-2/6"
              />
            </router-link>
          </div>
          <div>
            <button
              @click="logout"
              class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-4 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
      <div class="grid" v-if="catalog">
        <div class="flex gap-2 mt-4 p-4 overflow-x-auto" v-if="categories">
          <div
            v-for="cat in categories"
            :key="cat"
            :class="{
              'bg-dTabColor text-nColorButtonText': selectedCat === cat,
              'bg-transparent text-dTabColor': selectedCat != cat,
            }"
            class="border border-dTabColor rounded-2xl font-InterSemiBold text-sm px-3 py-0.5 capitalize cursor-pointer"
            @click="selectedCat = cat"
          >
            {{ cat }}
          </div>
        </div>
        <div class="grid grid-cols-2 gap-2 p-4">
          <div
            v-for="game in getGames"
            :key="game.slug"
            class="bg-nColorTextInput rounded-2xl grid justify-items-center content-center gap-4 relative cursor-pointer"
            @click.prevent="goToGame(game.slug)"
          >
            <div>
              <img :src="gameTile(game.slug)" :alt="game.name" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "Catalog",
  components: {},
  data() {
    return {
      catalog: null,
      basePath: null,
      categories: null,
      selectedCat: null,
    };
  },
  created() {
    ApiService.get(apiResource.getCatalogDetails, {}).then((data) => {
      this.catalog = data.data.data.games;
      this.basePath = data.data.data.base_path;
      this.categories = ["all", "action", "puzzle", "trivia", "word", "sport"];
      this.selectedCat = "all";
    });
  },
  mounted() {},
  computed: {
    getGames() {
      if (!this.catalog) return [];
      if (this.selectedCat === "all") {
        return Object.values(this.catalog).flat();
      }
      return this.catalog[this.selectedCat] || [];
    },
  },
  methods: {
    gameTile(slug) {
      return this.basePath + slug + ".webp";
    },
    goToGame(slug) {
      this.$router.push({
        name: "Game",
        params: {
          game: slug,
        },
      });
    },
    logout() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped></style>
